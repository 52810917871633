import {Helmet} from "react-helmet";
import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import facebook from '../images/facebook.png'
import remont1 from '../images/obsluzivanie.jpg'
import remont2 from '../images/dvigatel.jpg'
import remont3 from '../images/svarka.jpg'
import remont4 from '../images/tune.jpg'
import remont5 from '../images/diagnostika.jpg'
import remont6 from '../images/tormoz.jpg'
import remont7 from '../images/podveska.jpg'

const Translations_local = {
  ru: {
    service: "Наши услуги",
    hd1: "Обслуживание",
    txt1: "Производим полное обслуживание любых моделей бмв ,используя высококачественные масла ravenol i liqui moly",
    hd2: "Ремонт двигателя",
    txt2: "Ремонт и модифицирование двигателей любой сложности , под любые пожелания клиента  , используя , как оригинальные так и гоночные компоненты.",
    hd3: "Сварочные работы.",
    txt3: "Сварочные работы по нержавеющей стали, алюминию , так же замена деталей поврежденных корозией",
    hd4: "Тюнинг и модифицирование",
    txt4: "поднятие мощности (настройка и чиптюнинг, модификации системы наддува и  топливной системы, работы с подвеской и тормозами). Замена двигателя (swap).",
    hd5: "Диагностика",
    txt5: "Глубокая диагностика систем автомобиля , при необходимости возможен осмотр эндоскопом",
    hd6: "Тормоза",
    txt6: "Ремонт и модификации тормозной системы",
    hd7: "Подвеска",
    txt7: "Ремонт и улучшение ходовой части автомобиля с использованием различных гражданских и гоночных компонентов таких ,как kw ohlins i nitron"
  },
  lv: {
    service: "Mūsu pakalpojumi",
    hd1: "Apkope",
    txt1: "Motoreļļas maiņa dzinejā, ātrumkārbā un reduktorā, filtru, siksnu un motora ķēdes maiņa.",
    hd2: "Dzineja remonts",
    txt2: "Jebkuras sarežģītības dzinēju remonts un modifikācijas, pēc klienta vēlmēm, izmantojot gan oriģinālās, gan sacensību sastāvdaļas.",
    hd3: "Metināšanas darbi",
    txt3: "Nerūsējošā tērauda vai ​​alumīnija metināšanas darbi, kā arī korozijas bojāto detaļu maiņa",
    hd4: "Uzlabošana un modifikācija",
    txt4: "jaudas palielināšana (tunings un chiptunings, kompresijas un degvielas sistēmu modifikācijas, darbs ar piekarem un bremzēm). Dzinēja maiņa (swap).",
    hd5: "Diagnostika",
    txt5: "Transportlīdzekļa sistēmu padziļināta diagnostika, nepieciešamības gadījumā iespējama pārbaude ar endoskopu",
    hd6: "Bremzes",
    txt6: "Bremžu sistēmas remonts un modifikācija",
    hd7: "Apturēšana",
    txt7: "Automašīnas šasijas remonts un uzlabošana, izmantojot dažādas civilās un sacensību sastāvdaļas, piemēram, kw ohlins i nitron"
  },
  en: {
    service: "Our services",
    hd1: "Maintenance",
    txt1: "Change of engine oil in the engine, gearbox and reducer, change of filters, belts and engine chain.",
    hd2: "Engine repair",
    txt2: "Repair and modification of engines of any complexity, according to customers wishes, using both original and racing components.",
    hd3: "Welding works",
    txt3: "Welding work on stainless steel, aluminum, as well as replacement of parts damaged by corrosion",
    hd4: "Tuning and modification",
    txt4: "increasing power (tuning and chip tuning, modifications to the boost and fuel systems, suspension and brakes). Engine replacement (swap).",
    hd5: "Diagnostics",
    txt5: "Deep diagnostics of vehicle systems, if necessary, inspection with an endoscope is possible",
    hd6: "Brakes",
    txt6: "Repair and modification of the brake system",
    hd7: "Suspension",
    txt7: "Repair and improvement of the chassis of the car using various civil and racing components such as kw ohlins i nitron"
  }
};

const Fix = () => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
      const setLanguage_local = (language) => {
        document.getElementById("service").innerText = Translations_local[language].service;
        document.getElementById("hd1").innerText = Translations_local[language].hd1;
        document.getElementById("txt1").innerText = Translations_local[language].txt1;
        document.getElementById("hd2").innerText = Translations_local[language].hd2;
        document.getElementById("txt2").innerText = Translations_local[language].txt2;
        document.getElementById("hd3").innerText = Translations_local[language].hd3;
        document.getElementById("txt3").innerText = Translations_local[language].txt3;
        document.getElementById("hd4").innerText = Translations_local[language].hd4;
        document.getElementById("txt4").innerText = Translations_local[language].txt4;
        document.getElementById("hd5").innerText = Translations_local[language].hd5;
        document.getElementById("txt5").innerText = Translations_local[language].txt5;
        document.getElementById("hd6").innerText = Translations_local[language].hd6;
        document.getElementById("txt6").innerText = Translations_local[language].txt6;
        document.getElementById("hd7").innerText = Translations_local[language].hd7;
        document.getElementById("txt7").innerText = Translations_local[language].txt7;
      };

  setLanguage_local(language);
}, [language]);
    return( 
        <div className = 'App'>
            <h1 id = 'service'>Наши услуги</h1>
            <br/><br/>
            <div className = 'info_row'>
            <img src = {remont1} className = 'info_image_invis'/>
        <div className = 'textbox_left'>
          <h3 id = 'hd1'>Выпускные системы</h3>
          <p id = 'txt1'>Предоставляем услуги по изготовлению выпускных систем из нержавеющей стали, как целиком, так и частично. Изготавливаем
             и рассчитываем выпускные коллектора на все виды а/м. Идивидуальный подход к каждому проекту. 
             Предоставляется возможность выбора материалов, компонентов и конструктива в каждой системе выпуска.</p>
        </div>
        <div className = 'breaker'></div>
        <img src = {remont1} className = 'info_image_right-fix'/>
      </div>
      <br/><br/>
      <div className = 'info_row'>
        <img src = {remont2} className = 'info_image-fix'/>
        <div className = 'breaker'></div>
        <div className = 'textbox'>
          <h3 id = 'hd2'>Подвеска</h3>
          <p id = 'txt2'>У нас есть возможность заказать любые комплекты и комплектующие для подвесок различных автомобилей.
             Так же мы занимаемся разработкой и изготовлением комплектов подвески для автомобиля,
              если для данной модели на рынке ничего нет.</p>
        </div>
      </div>
      <br/><br/>
      <div className = 'info_row'>
      <img src = {remont6} className = 'info_image_invis'/>
        <div className = 'textbox_left-trmz'>
          <h3 id = 'hd6'>Сварочные работы</h3>
          <p id = 'txt6'>Предоставляем услуги по выполнению сварочных работ любой сложности — как кузовных, так и по нержавеющей стали и алюминию.</p>
        </div>
        <div className = 'breaker'></div>
        <img src = {remont6} className = 'info_image_right-fix'/>
      </div>
      <br/><br/>
      <div className = 'info_row'>
        <img src = {remont7} className = 'info_image-fix'/>
        <div className = 'breaker'></div>
        <div className = 'textbox'>
          <h3 id = 'hd7'>Подвеска</h3>
          <p id = 'txt7'>У нас есть возможность заказать любые комплекты и комплектующие для подвесок различных автомобилей.
             Так же мы занимаемся разработкой и изготовлением комплектов подвески для автомобиля,
              если для данной модели на рынке ничего нет.</p>
        </div>
      </div>
      <br/><br/>
      <div className = 'info_row'>
      <img src = {remont3} className = 'info_image_invis'/>
        <div className = 'textbox_left'>
          <h3 id = 'hd3'>Сварочные работы</h3>
          <p id = 'txt3'>Предоставляем услуги по выполнению сварочных работ любой сложности — как кузовных, так и по нержавеющей стали и алюминию.</p>
        </div>
        <div className = 'breaker'></div>
        <img src = {remont3} className = 'info_image_right-fix'/>
      </div>
      <br/><br/>
      <div className = 'info_row'>
        <img src = {remont4} className = 'info_image-fix'/>
        <div className = 'textbox-tune'>
          <h3 id = 'hd4'>Свапы</h3>
          <p id = 'txt4'>Предоставляем возможность установки любого выбранного вами двигателя в ваш BMW, 
            начиная от М50, и заканчивая S85. Практически все виды свапов подлежат сертификации в CSDD.</p>
        </div>
      </div>
      <br/><br/>
      <div className = 'info_row'>
      <img src = {remont5} className = 'info_image_invis'/>
      <div className = 'breaker'></div>
        <div className = 'textbox_left'>
          <h3 id = 'hd5'>Сварочные работы</h3>
          <p id = 'txt5'>Предоставляем услуги по выполнению сварочных работ любой сложности — как кузовных, так и по нержавеющей стали и алюминию.</p>
        </div>
        <img src = {remont5} className = 'info_image_right-fix'/>
      </div>
      <br/><br/><br/><br/><br/>
      <footer className = 'footer'>
      <div className = 'line'>
        <ion-icon name="call"></ion-icon>
        <p>+371 26 097 304</p>
      </div>
      <div className = 'line'>
        <ion-icon name="mail"></ion-icon>
        <p>customcars@inbox.lv</p>
      </div>
      <div className = 'line'>
        <ion-icon name="location"></ion-icon>
        <p>Rīga, VEF, Ūnijas 11b</p>
      </div>
      </footer>

      <Helmet>
        <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </Helmet>
        </div>
    );
}

export default Fix;