import React, { useEffect, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import {Helmet} from "react-helmet";
import '../stylesheets/App.css';
import facebook from '../images/facebook.png'
import main_img from '../images/main-img.jpg'
import main_img_small from '../images/main-img-small.jpg'
import main_img_en from '../images/main-img-en.jpg'
import main_img_small_en from '../images/main-img-small-en.jpg'
import main_img_lv from '../images/main-img-lv.jpg'
import main_img_small_lv from '../images/main-img-small-lv.jpg'
import custom1 from '../images/custom1.jpg'
import custom2 from '../images/custom2.jpg'
import remont1 from '../images/obsluzivanie.jpg'
import remont2 from '../images/dvigatel.jpg'
import project1 from '../images/project1.jpg'
import project2 from '../images/project2.jpg'
import project3 from '../images/project3.jpg'
import project4 from '../images/project4.jpg'

const Translations_local = {
  ru: {
      abt: "О нас",
      text_abt: "Наш опыт ремонта, обслуживания и улучшения BMW больше 20ти лет. Дизельные, бензиновые, с турбиной или без, М-серия - мы знакомы со всеми из них, изучили их сильные и слабые стороны и готовы помочь вам с грамотным обслуживанием, качественным ремонтом и модифицированием.",
      more: "Узнать больше",
      det_head: "Впускной коллектор s63 с улучшенной пропускной способностью",
      det_head2: "Опоры дифференциала для автомобиля с двигателем S63",
      custom: "Кастомные детали",
      custom_more: "Больше кастомных деталей",
      fix: "Наши услуги",
      serv1: "Обслуживание",
      serv1_desc: "замена масла в двигателе, коробке передач и редукторе, фильтров, ремней и цепи двигателя.",
      serv2: "Ремонт", 
      serv2_desc: "Ремонт и модифицирование двигателей любой сложности , под любые пожелания клиента  , используя , как оригинальные так и гоночные компоненты.",   
      serv_more: "Полный список услуг",
      proj: "Наши проекты",
      proj_more: "Галлерея проектов",
      contact: "Контакты",
      IMG: main_img,
      IMG_sml: main_img_small
  },
  lv: {
      abt: "Par mums",
      text_abt: "Mūsu pieredze BMW remontā, apkopē un uzlabošanā ir vairāk nekā 20 gadi. Dīzelis, benzīns, ar vai bez turbīnas, M-sērija - mēs pazístam tos visus, esam izpētījuši to stiprās un vājās puses un esam gatavi jums palīdzēt ar kompetentu apkopi, kvalitatīvu remontu un modifikācijām.",
      more: "Skatīties vairāk",
      det_head: "S63 dzinēja Ieplūdes kolektori",
      det_head2: "Diferenciāla balsti priekš auto ar S63 dzinēju",
      custom: "pielagotas detaļas",
      custom_more: "Vairāk pielāgotu detaļu",
      fix: "Mūsu pakalpojumi",
      serv1: "Apkope",
      serv1_desc: "Motoreļļas maiņa dzinejā, ātrumkārbā un reduktorā, filtru, siksnu un motora ķēdes maiņa.",
      serv2: "Remonts", 
      serv2_desc: "Jebkuras sarežģītības dzinēju remonts un modifikācijas, pēc klienta vēlmēm, izmantojot gan oriģinālās, gan sacensību sastāvdaļas.",
      serv_more: "Pilns pakalpojumu saraksts",
      proj: "Mūsu projekti",
      proj_more: "Projektu galerija",
      contact: "Kontakti",
      IMG: main_img_lv,
      IMG_sml: main_img_small_lv
  },
  en: {
      abt: "About us",
      text_abt: "Our experience in repair, maintenance and improvement of BMW is more than 20 years. Diesel, petrol, with or without a turbine, M-series - we are familiar with all of them, have studied their strengths and weaknesses and are ready to help you with competent maintenance, high-quality repair and modification.",
      more: "Know more",
      det_head: "S63 engine intake manifolds",
      det_head2: "Differential mounts to be used on car with S63 engine",
      custom: "Custom parts",
      custom_more: "More custom parts",
      fix: "Our services",
      serv1: "Maintenance",
      serv1_desc: "Change of engine oil in the engine, gearbox and reducer, change of filters, belts and engine chain.",
      serv2: "Repair",
      serv2_desc: "Repair and modification of engines of any complexity, according to customers wishes, using both original and racing components.",
      serv_more: "Full service list",
      proj: "Our projects",
      proj_more: "Project gallery",
      contact: "Contacts",
      IMG: main_img_en,
      IMG_sml: main_img_small_en
  }
};

const Home = () => {
  const { language } = useContext(LanguageContext);
  const [imageSrc, setImageSrc] = useState('');

  const updateImageSrc = () => {
    const width = window.innerWidth;
    
    if (width <= 426) {
      setImageSrc(Translations_local[language].IMG_sml);
    } else {
      setImageSrc(Translations_local[language].IMG); 
    }
  };

  useEffect(() => {

    updateImageSrc(); // Initial check when the component mounts
    window.addEventListener('resize', updateImageSrc); // Add resize listener

    const setLanguage_local = (language) => {
        document.getElementById("abt2").innerText = Translations_local[language].abt;
        document.getElementById("text_abt").innerText = Translations_local[language].text_abt;
        document.getElementById("custom2").innerText = Translations_local[language].custom;
        document.getElementById("detail_head1").innerText = Translations_local[language].det_head;
        document.getElementById("detail_head2").innerText = Translations_local[language].det_head2;
        document.getElementById("cust_more").innerText = Translations_local[language].custom_more;
        document.getElementById("fix2").innerText = Translations_local[language].fix;
        document.getElementById("serv1").innerText = Translations_local[language].serv1;
        document.getElementById("serv1_desc").innerText = Translations_local[language].serv1_desc;
        document.getElementById("serv2").innerText = Translations_local[language].serv2;
        document.getElementById("serv2_desc").innerText = Translations_local[language].serv2_desc;
        document.getElementById("serv_more").innerText = Translations_local[language].serv_more;
        document.getElementById("proj2").innerText = Translations_local[language].proj;
        document.getElementById("proj_more").innerText = Translations_local[language].proj_more;
    };

    setLanguage_local(language);
}, [language]);

  return (
    <div className="App">
      <div className = 'Main-pic'>
        <img src = {imageSrc} id = "main-img" className = 'main-img'/>
      </div>
      <h2 id = 'abt2'>О нас</h2>
      <p className= 'text_abt' id = 'text_abt'>Здесь будет текст о нас</p>
      <br/><br/>
      <h2 id = "custom2">Кастомные детали</h2>
      <div className = 'info_row'>
        <img src = {custom1} class = 'info_image'/>
        <div className = 'textbox'>
          <h3 id = "detail_head1">Заголовок детали</h3>
        </div>
      </div>
      <br/>
      <div className = 'info_row'>
        <img src = {custom2} className = 'info_image_invis'/>
        <div className = 'textbox_left'>
          <h3 id = "detail_head2">Заголовок детали</h3>
        </div>
        <img src = {custom2} className = 'info_image_right'/>
      </div>
      <br/><br/>
      <Link to = '/Custom'><button className = 'more_button' id = "cust_more">Больше кастомных деталей</button></Link>
      <br/><br/>
      <h2 id = "fix2">Наши услуги</h2>
      <br/>
      <div className = 'info_row'>
      <img src = {remont1} className = 'info_image_invis'/>
        <div className = 'textbox_left'>
          <h3 id = "serv1">Выпускные системы</h3>
          <p id = "serv1_desc">Предоставляем услуги по изготовлению выпускных систем из нержавеющей стали, как целиком, так и частично. Изготавливаем
             и рассчитываем выпускные коллектора на все виды а/м. Идивидуальный подход к каждому проекту. 
             Предоставляется возможность выбора материалов, компонентов и конструктива в каждой системе выпуска.</p>
        </div>
        <img src = {remont1} className = 'info_image_right'/>
      </div>
      <br/><br/>
      <div className = 'info_row'>
        <img src = {remont2} className = 'info_image'/>
        <div className = 'textbox'>
          <h3 id = "serv2">Подвеска</h3>
          <p id = "serv2_desc">У нас есть возможность заказать любые комплекты и комплектующие для подвесок различных автомобилей.
             Так же мы занимаемся разработкой и изготовлением комплектов подвески для автомобиля,
              если для данной модели на рынке ничего нет.</p>
        </div>
      </div>
      <br/><br/>
      <Link onClick={() => {window.location.href="/fix"}}><button className = "more_button" id = "serv_more">Полный список услуг</button></Link>
      <br/><br/>
      <h2 id = "proj2">Наши проекты</h2>
      <div Classname = 'info_row'>
        <img src = {project1} className = 'info_image'/>
        <img src = {project2} className = 'info_image_invis'/>
        <img src = {project2} className = 'info_image_right'/>
      </div>
      <div Classname = 'info_row'>
        <img src = {project3} className = 'info_image'/>
        <img src = {project4} className = 'info_image_invis'/>
        <img src = {project4} className = 'info_image_right'/>
      </div>
      <br/>
      <Link onClick={() => {window.location.href="/Projects"}}><button className = 'more_button' id = "proj_more">Галлерея проектов</button></Link>
      <br/><br/><br/><br/><br/>
      <footer className = 'footer'>
      <div className = 'line'>
        <ion-icon name="call"></ion-icon>
        <p>+371 26 097 304</p>
      </div>
      <div className = 'line'>
        <ion-icon name="mail"></ion-icon>
        <p>customcars@inbox.lv</p>
      </div>
      <div className = 'line'>
        <ion-icon name="location"></ion-icon>
        <p>Rīga, VEF, Ūnijas 11b</p>
      </div>
      </footer>

      <Helmet>
        <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </Helmet>
    </div>
  );
};

export default Home;
