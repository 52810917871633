import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Link } from "react-router-dom";
import '../stylesheets/App.css';
import facebook from '../images/facebook.png';
import { LanguageContext } from "../Contexts/Languagecontext.js";
import { FaBars, FaTimes } from 'react-icons/fa';

const Translations = {
    ru: {
        abt: "О нас",
        custom: "Кастомные детали",
        fix: "Наши услуги",
        proj: "Наши проекты",
        contact: "Контакты"
    },
    lv: {
        abt: "Par mums",
        custom: "pielagotas detaļas",
        fix: "Mūsu pakalpojumi",
        proj: "Mūsu projekti",
        contact: "Kontakti"
    },
    en: {
        abt: "About us",
        custom: "Custom parts",
        fix: "Our services",
        proj: "Our projects",
        contact: "Contacts"
    }
};

const Base = () => {
    const { language, changeLanguage } = useContext(LanguageContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1500);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1500);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        changeLanguage(newLanguage);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <>
            <header className="App-header">
                {isMobileView ? (
                    <>
                        <div className="dropdown-toggle" onClick={toggleDropdown}>
                            {isDropdownOpen ? <FaTimes /> : <FaBars />}
                        </div>
                        <div className="dropdown-menu" style={{ display: isDropdownOpen ? 'flex' : 'none' }}>
                            <div className="Links-mobile">
                                <Link to='/' className="Link" onClick={toggleDropdown}>{Translations[language].abt}</Link>
                                <Link to='Custom' className="Link" onClick={toggleDropdown}>{Translations[language].custom}</Link>
                                <Link to='Fix' className='Link' onClick={toggleDropdown}>{Translations[language].fix}</Link>
                                <Link to='Projects' className='Link' onClick={toggleDropdown}>{Translations[language].proj}</Link>
                                <Link to='Contacts' className='Link' onClick={toggleDropdown}>{Translations[language].contact}</Link>
                                <a href='https://www.facebook.com/ixt.lv/'>
                                    <img src={facebook} className='facebook' alt="Facebook" />
                                </a>
                                <select name='lang_id' id='lang' className='select' value={language} onChange={handleLanguageChange}>
                                    <option value='ru'>Русский</option>
                                    <option value='lv'>Latviešu</option>
                                    <option value='en'>English</option>
                                </select>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="Links-desk">
                        <Link to='/' className="Link">{Translations[language].abt}</Link>
                        <Link to='Custom' className="Link">{Translations[language].custom}</Link>
                        <Link to='Fix' className='Link'>{Translations[language].fix}</Link>
                        <Link to='Projects' className='Link'>{Translations[language].proj}</Link>
                        <Link to='Contacts' className='Link'>{Translations[language].contact}</Link>
                        <a href='https://www.facebook.com/ixt.lv/'>
                            <img src={facebook} className='facebook' alt="Facebook" />
                        </a>
                        <select name='lang_id' id='lang' className='select' value={language} onChange={handleLanguageChange}>
                            <option value='ru'>Русский</option>
                            <option value='lv'>Latviešu</option>
                            <option value='en'>English</option>
                        </select>
                    </div>
                )}
            </header>
            <Outlet />
        </>
    );
};

export default Base;