import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Home.js'
import Custom from './custom.js'
import Fix from './fix.js'
import Projects from './projects.js'
import Contacts from './contacts.js'
import Base from './Base.js'
import facebook from '../images/facebook.png'
import { LanguageProvider } from "../Contexts/Languagecontext.js";

export default function App() {
    return(
        <LanguageProvider>
        <BrowserRouter>
            <Routes>
                <Route path = '/' element = {<Base />}>
                    <Route index element = {<Home />}></Route>
                    <Route path = 'custom' element = {<Custom />}></Route>
                    <Route path = 'fix' element = {<Fix />}></Route>
                    <Route path = 'projects' element = {<Projects/>}></Route>
                    <Route path = 'contacts' element = {<Contacts/>}></Route>
                </Route>
            </Routes>
        </BrowserRouter>
        </LanguageProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
