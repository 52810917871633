import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import {Helmet} from "react-helmet";
import '../stylesheets/App.css';
import facebook from '../images/facebook.png'
import project1 from '../images/project1.jpg'
import project2 from '../images/project2.jpg'
import project3 from '../images/project3.jpg'
import project4 from '../images/project4.jpg'
import project5 from '../images/project5.jpg'
import project6 from '../images/project6.jpg'

const Translations_local = {
    ru: {
        name: "Галлерея проектов",
        car1: "E24 4.4",
        txt1: " двигатель M62B44, стандартный ECU, выпускные коллекторы E53 4.8, выпуск из нержавеющей стали 2x63mm с наконечниками Remus, тормозная система от праворульной E34 540i c тормозными трубками IXT Performance, модифицированная винтовая подвеска от E39, колёса Hamann Motorsport R18",
        car2: "E24 turbo",
        txt2: "двигатель M30B35, VEMS ECU, поршни M102, прокладка головы на пирамидальных кольцах, впускной коллектор Alpina style, форсунки Siemens 700cc, топливный насос Bosch 044, twinscroll турбина на равнодлинных выпускных коллекторах, 90мм даунпайп IXT, выпуск 76мм IXT, коробка передач Getrag, сцепление XTR, маховик s38, задний редуктор 3.15 с блокировкой от Е46 М3, модифицированная винтовая подвеска, колёса Rial Daytona Race R18 cо сделанными на заказ полками, модифицированные поворотники и фонари (smoked)",
        car3: "E46 M3 (реплика M3 CSL)",
        txt3: "объём двигателя увеличен до 3.3, поршни JE, шатуны FCP, распредвалы 288/280, титановые ритейнеры и гоночные пружины клапанов, впуск Carbonius Race, выпускные коллекторы Supersprint Race, средняя секция выпуска 63мм IXT, титановая задняя банка глушителя весом 9кг, винтовая подвеска KW v2, сделанные на заказ развальные плиты и рычаги, распорки H&R, тормозная система с суппортами от 996 и BMW Performance, тормозные диски M3 CSL, тормозные трубки HEL, бампера CSL спереди и сзади, крышка багажника CSL, накладки на пороги CSL, руль Sparco, сиденье Sparco, ремни Sparco, сделанный на заказ полукаркас безопасности, сделанные на заказ детали внешности из карбона включая задний диффузор, дисплей EGT Control, лёгкий аккумулятор, колёса BBS CHR",
        car4: "E46 335i",
        txt4: "двигатель N54B30, опоры двигателя IXT, вся проводка и модули от E92, блоки управления стёклами и зеркалами IXT, двойной топливный насос низкого давления в баке, регулятор давления топлива AEM, тефлоновые топливопроводы, топливный насос высокого давления с отдельным модулем контроля, шкивы IXT, шпильки головы ARP, поршни и шатуны CP Carillo, портированная голова, распредвалы Cat, выпускные коллекторы IXT, два турбонагнетателя Gtx28, выпуск IXT, интеркулер Wagner, коробка передач от 330i (ручная 6 скоростей), шортшифтер Z4M, кардан и задний дифференциал от M3 E46, полуоси от 330d, cцепление Spec R Super Twin, cтабилизаторы М3 E46, тормозные диски M3 CSL E46, задние суппорта Porsche 996, передние суппорта BMW Performance, рулевые адаптеры IXT, винтовая подвеска HSD, развальные рычаги IXT, сайлентблоки M3 E46, зеркала E82, модифицированный передний бампер, пороги M-tech",
        car5: "F06 650xi N63",
        txt5: "гильзованный блок двигателя, поршни и шатуны CP Carillo, портированные головы, сбалансированный коленвал, модифицированные распредвалы, топливный насос высокого давления M5 f90, топливный насос низкого давления 700l/h, регулятор давления топлива DW, тефлоновые топливопроводы IXT, топливный фильтр Inline, впускные коллекторы IXT из алюминия, выпускные коллекторы IXT, даунпайпы IXT, выпуск BMW M6, два турбонагнетателя G25 660, интеркулеры CSF, cистема охлаждения гибрид деталей 650 и М6, редуктор 3.08, усиленная коробка передач, впуск этанола, дополнительные радиаторы и вентилятор для охлаждения топливной системы, модифицированная тормозная система от BMW M5 F90, винтовая подвеска KW v3, сделанные на заказ кованые диски R20, модифицированный капот",
        car6: "F13 M6 S63",
        txt6: "гильзованный блок двигателя, поршни JE, шатуны DP, шпильки головы ARP, портированные головы, два турбонагнетателя TTE 1100, интеркулеры CSF, впрыск этанола Aquamist, усиленное cцепление Deka Clutch ST4, поддон коробки Billet DCT pan, крепление дифференциала Billet diff mount, винтовая подвеска KW v3, кованые диски BC Forged R21, карбоновый диффузор 3DDesign"
    },
    lv: {
        name: "Projektu galerija",
        car1: "E24 4.4",
        txt1: "dzinējs M62B44, standarta ECU, izplūdzes kolektori E53 4.8, nerusējoša tērauda izplūde 2x63mm ar Remus uzgaliem, bremžu sistēma no RHD E34 540i ar bremžu trubiņām IXT Performance, modifīcēta piekare ar koiloveriem E39, diski Hamann Motorsport R18",
        car2: "E24 turbo",
        txt2: "dzinējs M30B35, VEMS ECU, M102 virzuļi, galvas blīve uz piramidāliem gredzeniem, ieplūdes kolektors Alpina style, sprauslas Siemens 700cc, degvielas sūknis Bosch 044, twinscroll turbīna uz vienāda garuma izplūdes kolektora, 90мм daunpaips IXT, izplūde 76mm IXT, Getrag ātrumkārba, XTR sajūgs, spārrats S38, aizm.reduktors 3.15 ar bloķi no Е46 М3, modificēta piekare ar koiloveriem, rati Rial Daytona Race R18 ar uztaisītiem pēc pasūtījuma plauktiem, modificētie pagriezienrādītāji un aizm. lukturi (smoked)",
        car3: "E46 M3 (M3 CSL replika)",
        txt3: "dzinēja tilpums palielināts līdz 3.3, JE virzuļi, FCP klāņi, sadales vārpstas 288/280, titāna riteineri un sacīkšu vārstu atsperes, ieplūde Carbonius Race, izplūdes kolektori Supersprint Race, vidējā izplūdes sekcija 63mm IXT, izplūdes aizm.bundulis no titāna 9kg, koiloveri KW v2, uztaisītie pēc pasūtījuma savirzes plīti un sviras, statnes stienis H&R, bremžu sistēma ar suportiem no Porsche 996 un BMW Performance, bremžu diski M3 CSL, bremžu trubiņas HEL, CSL bamperi priekšā un aizmugurē, bagāžnieka vāks CSL, sliekšņu uzlikas  CSL, Sparco stūre, sīksnas un sēdeklis, pec pasūtījuma taisītais drošības puskarkass, pēc pasūtījuma taisītas karbona detaļas ieskaitot aizmugures difuzoru, EGT Control displejs, viegls akumulators, diski BBS CHR",
        car4: "E46 335i",
        txt4: "dzinējs, dzinēja balsti IXT, E92 vadi un visi vadības moduļi, IXT stiklu un spoguļu vadības moduļi, dubultais zemspiediena degvielas sūknis bākā, degvielas spiediena regulators AEM, teflona degvielas vadi, augstspiediena degvielas sūknis ar atsevišķo kontroles moduli, IXT skriemeļi, ARP galvas tapas, virzuļi un klāņi CP Carillo, portēta galva, sadales vārpstas Cat, izplūdes kolektori IXT, divas turbīnas Gtx28, IXT izplūde, Wagner starpdzēsētājs, ātrumkārba no 330i (manuālā 6 ātrumi), Z4M šortšifteris, kardāns un aizm.diferenciālis no M3 E46, pusasis no 330d, sajūgs Spec R Super Twin, stabilizātori М3 E46, bremžu diski M3 CSL E46, aizm.suporti Porsche 996, priekšējie suporti BMW Performance, stūrēšanas adapteri IXT, HSD koiloveri, savirzes sviras IXT, M3 E46 sailentbloķi, E82 spoguļi, modificētais priekšējais bamperis, sliekšņi M-tech",
        car5: "F06 650xi N63",
        txt5: "dzinēja bloks ar piedurknēm, virzuļi un klāņi CP Carillo, portētas galvas, balancēta kloķvārpsta, modificētas sadales vārpstas, augstspiediena degvielas sūknis M5 f90, zemspiediena degvielas sūknis 700l/h, DW degvielas spiediena regulātors, teflona degvielas vadi IXT, degvielas filtrs Inline, ieplūdes kolektori IXT no alumīnija, izplūdes kolektori IXT, daunpaipi IXT, izplūde BMW M6, divas turbīnas G25 660, CSF starpdzēsētāji, dzēsēšanas sistēma hibrīds no 650 un М6 detaļām, reduktors 3.08, modificēta ātrumkārba, etanola ieplūde, papildus radiatori un ventilātors degvielas sistēmas dzēsēšanai, modificēta bremžu sistēma no BMW M5 F90, koiloveri KW v3, pēc pasūtījuma taisītie kaltie diski R20, modificēta haube",
        car6: "F13 M6 S63",
        txt6: "dzinēja bloks ar piedurknēm, virzuļi JE, klāņi DP, ARP galvas tapas, portētas galvas, divas turbīnas TTE 1100, CSF starpdzēsētāji, etamola ieplūde Aquamist, pastiprināts sajūgs Deka Clutch ST4, kārbas paliktnis Billet DCT pan, diferenciāla balsts Billet diff mount, koiloveri KW v3, kaltie diski BC Forged R21, karbona difuzors 3DDesign"
    },
    en: {
        name: "Project gallery",
        car1: "E24 4.4",
        txt1: "M62B44 engine, stock ECU, E53 4.8 headers, stainless steel exhaust 2x63mm with  Remus tailpipes, e34 540i RHD brake sistem with custom linings, E39 coilovers customized to chassis, Hamann Motorsport R18 wheels",
        car2: "E24 turbo",
        txt2: "M30B35 engine, VEMS ECU, M102 pistons, head gasket with pyramid rings, Alpina style intake manifold, Siemens 700cc injectors, fuel pump Bosch 044, twinscroll turbocharger on equal length exhaust manifold, 90mm downpipe IXT, exhaust 76mm IXT, gearbox Getrag, XTR clutch, S38 flywheel, 3.15 diff with Е46 М3 LSD, modified coilovers, wheels Rial Daytona Race R18 with custom lips, smoked tail lights and turn indicators",
        car3: "E46 M3 (M3 CSL tribute)",
        txt3: "3.3L engine, pistons JE, FCP rods, cams 288/280, titanium retainers and racing valve springs, intake Carbonius Race, Supersprint Race headers, middle exhaust section 63мм IXT,  titanium muffler 9kg, KW v2 coilovers, custom camber plates and camber arms, H&R strut bars, Porsche 996 and BMW Performance brake callipers, brake rotors M3 CSL, HEL brake linings, CSL front and rear bumpers, trunk, scuff plates, Sparco steering wheel, seat, belts, custom halfcage, custom carbon trim, EGT Control display, lightweight accu, BBS CHR wheels",
        car4: "E46 335i",
        txt4: "N54B30 engine, IXT engine mounts, all body wiring and modules from e92, custom controller for windows and mirrors IXT, Twin lpfp, fuel pressure regulator AEM, custom teflon piping, second hpfp with standalone controller, IXT pulleys, ARP head studs, pistons and rods CP Carillo, ported head, Cat cams, IXT headers, two turbochargers Gtx28, IXT exhaust, Wagner intercooler, 330i gearbox (manual 6 speed), Z4M shortshifter, M3 E46 shaft, 330d halfshafts, Spec R Super Twin clutch, stabilizers М3 E46, M3 CSL E46 rotors, rear calipers Porsche 996, front calipers BMW Performance, steering adaptors IXT, HSD coilovers, IXT cnc adaptors, M3 E46 bushings, E82 mirrors, modified front bumper, M-tech sideskirts",
        car5: "F06 650xi N63",
        txt5: "5.  sleeved block, pistons and rods CP Carillo, ported heads, balanced crank, modified cams, hpfp M5 f90, lpfp 700l/h, fuel pressure regulator DW, teflon fuel lines IXT, fuel filter Inline, aluminium intake IXT, headers IXT, downpipes IXT, exhaust BMW M6, two turbochargers G25 660, intercoolers CSF, cooling sistem hybrid from 650 and m6 parts, diff 3.08, modified gearbox, ethanol injection, custom radiators and cooling fan for fuel sistem, modified brakes from BMW M5 F90, coilovers KW v3, custom forged R20 wheels, modified hood",
        car6: "F13 M6 S63",
        txt6: " sleeved block, JE pistons, DP rods, ARP head studs, ported heads, two turbochargers TTE 1100, CSF intercoolers, ethanol injection Aquamist, custom clutch Deka Clutch ST4, gearbox pan Billet DCT, Billet diff mount, coilovers KW v3, forged wheels BC Forged R21, CF diffusor 3DDesign"
    }
};

const Projects = () => {
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const setLanguage_local = (language) => {
            document.getElementById("name").innerText = Translations_local[language].name;
            document.getElementById("car1").innerText = Translations_local[language].car1;
            document.getElementById("txt1").innerText = Translations_local[language].txt1;
            document.getElementById("car2").innerText = Translations_local[language].car2;
            document.getElementById("txt2").innerText = Translations_local[language].txt2;
            document.getElementById("car3").innerText = Translations_local[language].car3;
            document.getElementById("txt3").innerText = Translations_local[language].txt3;
            document.getElementById("car4").innerText = Translations_local[language].car4;
            document.getElementById("txt4").innerText = Translations_local[language].txt4;
            document.getElementById("car5").innerText = Translations_local[language].car5;
            document.getElementById("txt5").innerText = Translations_local[language].txt5;
            document.getElementById("car6").innerText = Translations_local[language].car6;
            document.getElementById("txt6").innerText = Translations_local[language].txt6;
        };

    setLanguage_local(language);
}, [language]);
    return( 
        <div className = 'App'>
            <h1 id = 'name'>Галлеря проектов</h1>
            <br/>
            <div className = 'info_row'>
                <img src = {project2} className = 'info_image_invis'/>
                <div className = 'textbox_left-proj'>
                    <h3 id = "car1"></h3>
                    <p id = "txt1">Плэйсхолдер</p>
                </div>
                <img src = {project2} className = 'info_image_right-proj'/>
            </div>
            <br/>
            <div className = 'info_row'>
                <img src = {project1} class = 'info_image-proj'/>
                <div className = 'textbox-proj'>
                    <h3 id = "car2"></h3>
                    <p id = "txt2">Плэйсхолдер</p>
                </div>
            </div>
            <br/>
            <div className = 'info_row'>
                <img src = {project4} className = 'info_image_invis'/>
                <div className = 'textbox_left-proj'>
                    <h3 id = "car3"></h3>
                    <p id = "txt3">Плэйсхолдер</p>
                </div>
                <img src = {project4} className = 'info_image_right-proj'/>
            </div>
            <br/>
            <div className = 'info_row'>
                <img src = {project3} class = 'info_image-proj'/>
                <div className = 'textbox-proj'>
                    <h3 id = "car4"></h3>
                    <p id = "txt4">Плэйсхолдер</p>
                </div>
            </div>
            <br/>
            <div className = 'info_row'>
                <img src = {project6} className = 'info_image_invis'/>
                <div className = 'textbox_left-proj'>
                    <h3 id = "car5"></h3>
                    <p id = "txt5">Плэйсхолдер</p>
                </div>
                <img src = {project6} className = 'info_image_right-proj'/>
            </div>
            <br/>
            <div className = 'info_row'>
                <img src = {project5} class = 'info_image-proj'/>
                <div className = 'textbox-proj'>
                    <h3 id = "car6"></h3>
                    <p id = "txt6">Плэйсхолдер</p>
                </div>
            </div>
            <br/><br/><br/><br/><br/>
            <footer className = 'footer'>
                <div className = 'line'>
                    <ion-icon name="call"></ion-icon>
                    <p>+371 26 097 304</p>
                </div>
                <div className = 'line'>
                    <ion-icon name="mail"></ion-icon>
                    <p>customcars@inbox.lv</p>
                </div>
                <div className = 'line'>
                    <ion-icon name="location"></ion-icon>
                    <p>Rīga, VEF, Ūnijas 11b</p>
                </div>
            </footer>

        <Helmet>
            <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
            <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
        </Helmet>
        </div>
    );
}

export default Projects;