import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import {Helmet} from "react-helmet";
import facebook from '../images/facebook.png'
import custom1 from '../images/custom1.jpg'
import custom2 from '../images/custom2.jpg'
import custom3 from '../images/custom3.jpg'
import custom4 from '../images/custom4.jpg'

const Translations_local = {
    ru: {
     name: "Кастомные детали",
     entry: "Можно обращаться к нам с любыми идеями, а мы постараемся воплотить их в жизнь. Разработаем и изготавленим кастомную деталь из любых материалов на на трехосных и пятиосных CNC-станках",
     list: "Примеры наших деталей:",
     det_head: "Впускные коллекторы для двигателя S63",
     det_head2: "Опоры дифференциала для автомобиля с двигателем S63",
     det_head3: "Опора BMW E36 для установки шестиступенчатой коробки передач",
     det_head4: "Поддон коробки передач DCT, BMW M6"
    },
    lv: {
      name: "Pielāgotas detaļas",
      entry: "Jūs varat sazināties ar mums ar jebkādām idejām, un mēs centīsimies tās īstenot. Mēs izstrādāsim un izgatavosim pielāgotas detaļas no jebkura materiāla uz trīs asu un piecu asu CNC iekārtām",
      list: "Mūsu detaļu piemēri:",
      det_head: "S63 dzinēja Ieplūdes kolektori",
      det_head2: "Diferenciāla balsti priekš auto ar S63 dzinēju",
      det_head3: "6 ātrumu pārnesumkārbas balsts priekš BMW E36",
      det_head4: "DCT ātrumkārbas karteris, BMW M6"
    },
    en: {
      name: "Custom parts",
      entry: "You can contact us with any ideas, and we will try to bring them to life. We will develop and manufacture a custom part from any materials on three-axis and five-axis CNC machines",
      list: "Examples of our parts:",
      det_head: "S63 engine intake manifolds",
      det_head2: "Differential mounts to be used on car with S63 engine",
      det_head3: "6 speed manual gearbox mount for BMW E36",
      det_head4: "DCT gearbox sump, BMW M6"
    }
};

const Custom = () => {
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const setLanguage_local = (language) => {
            document.getElementById("name").innerText = Translations_local[language].name;
            document.getElementById("entry").innerText = Translations_local[language].entry;
            document.getElementById("list").innerText = Translations_local[language].list;
            document.getElementById("detail_head1").innerText = Translations_local[language].det_head;
            document.getElementById("detail_head2").innerText = Translations_local[language].det_head2;
            document.getElementById("detail_head3").innerText = Translations_local[language].det_head3;
            document.getElementById("detail_head4").innerText = Translations_local[language].det_head4;
        };

    setLanguage_local(language);
}, [language]);

    return( 
    <div className = "App">
        <h1 id = 'name'>Кастмоные детали</h1>
        <p id = 'entry'>Плейсхолдер</p>
        <br/>
        <h2 id = 'list'>Плейсхолдер</h2>
        <div className = 'info_row'>
        <img src = {custom1} class = 'info_image-fix-xtra'/>
        <div className = 'breaker'></div>
        <div className = 'textbox'>
          <h3 id = "detail_head1">Заголовок детали</h3>
        </div>
      </div>
      <br/>
      <div className = 'info_row'>
        <img src = {custom2} className = 'info_image_invis'/>
        <div className = 'textbox_left'>
          <h3 id = "detail_head2">Заголовок детали</h3>
        </div>
        <div className = 'breaker'></div>
        <img src = {custom2} className = 'info_image_right-fix-xtra'/>
      </div>
      <br/>
        <div className = 'info_row'>
        <img src = {custom3} class = 'info_image-fix'/>
        <div className = 'breaker'></div>
        <div className = 'textbox'>
          <h3 id = "detail_head3">Заголовок детали</h3>
        </div>
      </div>
      <br/>
      <div className = 'info_row'>
        <img src = {custom4} className = 'info_image_invis'/>
        <div className = 'textbox_left'>
          <h3 id = "detail_head4">Заголовок детали</h3>
        </div>
        <div className = 'breaker'></div>
        <img src = {custom4} className = 'info_image_right-fix'/>
      </div>
      <br/><br/><br/><br/><br/>
      <footer className = 'footer'>
      <div className = 'line'>
        <ion-icon name="call"></ion-icon>
        <p>+371 26 097 304</p>
      </div>
      <div className = 'line'>
        <ion-icon name="mail"></ion-icon>
        <p>customcars@inbox.lv</p>
      </div>
      <div className = 'line'>
        <ion-icon name="location"></ion-icon>
        <p>Rīga, VEF, Ūnijas 11b</p>
      </div>
      </footer>

      <Helmet>
        <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </Helmet>
    </div>
    );
}

export default Custom;
