import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import { LanguageContext } from "../Contexts/Languagecontext.js";
import facebook from '../images/facebook.png'
import contact1 from '../images/contacts1.jpg'
import contact2 from '../images/contacts2.jpg'

const Translations_local = {
    ru: {
        name: "Наши контакты"
    },
    lv: {
        name: "Mūsu kontakti"
    },
    en: {
        name: "Our contacts"
    }
};

const Contacts = () => {
    const { language } = useContext(LanguageContext);

    useEffect(() => {
        const setLanguage_local = (language) => {
            document.getElementById("name").innerText = Translations_local[language].name;
        };

    setLanguage_local(language);
}, [language]);
    return( 
        <div className = 'App'>
            <h1 id = 'name'>Наши контакты</h1>
            <div>
            <div className = 'line-cont'>
                <ion-icon name="call"></ion-icon>
                <p>+371 26 097 304</p>
            </div>
            <div className = 'line-cont'>
                <ion-icon name="mail"></ion-icon>
                <p>customcars@inbox.lv</p>
            </div>
            <div className = 'line-cont'>
                <ion-icon name="location"></ion-icon>
                <p>Rīga, VEF, Ūnijas 11b</p>
            </div>
            <div className='line-img'>
                <Link to = 'https://maps.app.goo.gl/9NUxe2ts65g1xD89A'>
                <img src = {contact1} class = 'info_image'/>
                </Link>
            </div>
            <div className='line-img'>
                <img src = {contact2} class = 'info_image'/>
            </div>
        </div>
        <br/><br/><br/>
        <footer className = 'footer'>
        </footer>

        <Helmet>
            <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
            <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
        </Helmet>
        </div>
    );
}

export default Contacts;