import React, { createContext, useState, useEffect } from 'react';

// Create the LanguageContext
const LanguageContext = createContext();

// Define the LanguageProvider component
const LanguageProvider = ({ children }) => {
    // Initialize the language state with the value from localStorage or default to 'ru'
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'ru');

    // Update localStorage whenever the language state changes
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    // Function to change the language and update the state
    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageProvider };